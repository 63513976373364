body,html {
  /* display: flex;
  flex-direction: column;
  align-items: center; */
  margin:0;
  width:100%;
  height:100vh;
}

.App {
  text-align: center;
  position: relative;;
}

.col{
  display: flex;
  flex-direction: column;
}

@media (min-width: 628px) {
  .Mobile{
    display: none;
  }
}
/* react pagination */
.pagination {
  margin: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  outline: none;
}
.pagination > .active > a{
  background-color: #C4C4C4 ;
  border-color: #C4C4C4 ;
  border-radius: 2px;
  color: #fff;
}
.pagination > li > a{
  border: 1px solid #C4C4C4 ;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}
.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
  background-color: #C4C4C4 ;
  border-color: #C4C4C4;
  outline: none ;
}
.pagination > li > a, .pagination > li > span{
  color: #C4C4C4
}
.pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span{
  border-radius: unset
}